export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30')
];

export const server_loads = [];

export const dictionary = {
		"/": [5],
		"/building_blocks": [6,[2]],
		"/building_blocks/new": [9,[2]],
		"/building_blocks/[id]": [7,[2]],
		"/building_blocks/[id]/[version]": [8,[2]],
		"/corsi": [10,[3]],
		"/corsi/new": [13,[3]],
		"/corsi/[id]": [11,[3]],
		"/corsi/[id]/[version]": [12,[3]],
		"/docenti": [14],
		"/docenti/new": [16],
		"/docenti/[id]": [15],
		"/learning_plans": [17,[4]],
		"/learning_plans/new": [20,[4]],
		"/learning_plans/[id]": [18,[4]],
		"/learning_plans/[id]/[version]": [19,[4]],
		"/notifications": [21],
		"/testimonials": [22],
		"/testimonials/new": [24],
		"/testimonials/[id]": [23],
		"/topic_categories": [25],
		"/topic_categories/new": [27],
		"/topic_categories/[id]": [26],
		"/utenti": [28],
		"/utenti/new": [30],
		"/utenti/[id]": [29]
	};

export const hooks = {
	handleError: (({ error }) => { console.error(error) }),
};

export { default as root } from '../root.svelte';